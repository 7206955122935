export default {
  // base theme...
  buttons: {
    primary: {
      fontWeight: "bold",
      color: "white",
      backgroundColor: "primary",
      "&:hover": {
        backgroundColor: "dark",
      },
    },
    secondary: {
      color: "colors.white",
      backgroundColor: "colors.secondary",
    },
    danger: {
      color: "colors.white",
      backgroundColor: "colors.red",
    },
  },
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: ".2em",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    display: {
      // extends the text.heading styles
      variant: "text.heading",
      fontSize: [6, 7, 8],
      fontWeight: "display",
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
  },
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 99999,
    },
  },
}
