/**
 * This theme uses `theme-ui` under the hood.
 * @see https://theme-ui.com/
 * @see https://theme-ui.com/gatsby-plugin/
 */
import { merge } from "lodash"
import colors from "./colors"
import components from "./components"
import layout from "./layout"
import styles from "./styles"
import typography from "./typography"

export default merge(typography, {
  useCustomProperties: true,
  colors,
  components,
  layout,
  styles,
})
