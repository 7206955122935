import "typeface-montserrat"
import "typeface-open-sans"
import "typeface-merriweather"
import "typeface-playfair-display"
import "typeface-fira-sans"

// import { merge } from "lodash"
import { toTheme } from "@theme-ui/typography"

// import sutroTheme from "typography-theme-sutro"
// export default toTheme(sutroTheme)

import kirkham from "typography-theme-kirkham"
export default toTheme(kirkham)
