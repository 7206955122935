export default {
  primary: `#0066f5`,
  secondary: `#022B69`,
  text: `#000000`,
  background: `#FFFFFF`,
  gold: `#b37100`,
  gray: `#232129`,
  green: "#007d48",
  muted: `hsla(0, 0%, 100%, 0.2)`,
  orange: `#ff8880`,
  pink: `#ffc4bf`,
  red: `#b30c00`,
  white: `#ffffff`,
  stone: `#005f70`,
  onahau: `#bff5ff`,
  anakiwa: `#80ebff`,
  peel: `#ffa100`,
  grandis: `#ffd080`,
  offwhite: `#ffe8bf`,
  navlink: `#0066f5`,
  prism: {
    background: `#011627`,
    comment: `#809393`,
    string: `#addb67`,
    var: `#d6deeb`,
    number: `#f78c6c`,
    constant: `#82aaff`,
    punctuation: `#c792ea`,
    className: `#ffc98b`,
    tag: `#ffa7c4`,
    boolean: `#ff5874`,
    property: `#80cbc4`,
    namespace: `#b2ccd6`,
    highlight: `hsla(207, 95%, 15%, 1)`,
  },
  modes: {
    dark: {
      primary: "#33ccff",
      secondary: "#ee00ff",
      text: "#ffffff",
      background: "#000000",
      green: "#007d48",
      pink: "#ff80b8",
      orange: "#ff8000",
      red: "#e60066",
      muted: `hsla(0, 0%, 100%, 0.2)`,
      offwhite: `#ffe8bf`,
      gray: `#232129`,
      navlink: `#0066f5`,
    },
  },
}
