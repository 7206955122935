export default {
  root: {},
  sizes: {
    container: 168,
  },
  header: {
    color: "white",
    bg: "primary",
  },
  main: { bg: "white" },
  container: {
    maxWidth: 1920,
    compact: {
      marginTop: 100,
    },
  },
  footer: {
    bg: "black",
    container: 768,
    navlink: "white",
    color: "white",
    a: {
      color: "white",
      textDecoration: "none",
      mx: "2",
      ":hover, :focus": {
        color: "primary",
      },
    },
  },
}
